/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for wizard.
 */
export default class extends Controller {
  static targets = ['step', 'showResults'];

  /**
   * Select option.
   *
   * @param {Event} e
   */
  selectOption(e) {
    const { dataset } = e.currentTarget;
    this.nextStep(parseInt(dataset.next, 10));
  }

  /**
   * Go to next step.
   *
   * @param {Number} next
   */
  nextStep(next) {
    if (next) {
      this.stepTargets.forEach((step, index) => {
        const controller = this.application.getControllerForElementAndIdentifier(step, 'wizard-option');
        if (!controller.containerTarget.classList.contains('hidden') || index === next) {
          controller.show(this.element);
        }
      });
    } else {
      this.showResultsTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => {
        this.showResultsTarget.disabled = false;
        this.showResultsTarget.classList.remove('cursor-not-allowed');
        this.showResultsTarget.classList.remove('opacity-50');
      }, 500);
    }
  }
}
