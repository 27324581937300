/* eslint-disable no-param-reassign */
/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Cookie from '../cookie';

export default class extends Controller {
  /**
   * Cookie storage key
   * @type {String}
   */
  storageKey = 'cookiePreferences';

  /**
   * Connect.
   */
  connect() {
    const cookie = Cookie.get(this.storageKey);

    Array.from(this.element.querySelectorAll('[rel="video"]')).forEach((link) => {
      if (!cookie || cookie === 'denied') {
        link.dataset.iframe = true;
        link.href = 'cookie-placeholder';
      }
    });

    this.dispatch('refresh');
  }
}
