/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for accordion.
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['panel'];

  /**
   * Read hash to open specific panel.
   */
  connect() {
    const { hash } = window.location;
    if (hash !== '') {
      this.panelTargets.forEach((panel) => {
        const target = `#${panel.href.split('#')[1]}`;
        this.toggle(panel, target === hash ? panel : false);
      });
    }
  }

  /**
   * Activate panel.
   *
   * @param {Event} e
   */
  activate(e) {
    e.preventDefault();

    this.panelTargets.forEach((panel) => {
      this.toggle(panel, e.target);
    });
  }

  /**
   * Toggle panel.
   *
   * @param {Event} e
   */
  toggle(panel, target) {
    const isTarget = panel === target;
    const toggle = isTarget ? undefined : !isTarget;

    if (isTarget) {
      target.classList.toggle(this.activeClass);
    } else {
      panel.classList.toggle(this.activeClass, isTarget);
    }

    panel.nextElementSibling.classList.toggle('hidden', toggle);
  }
}
