/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import _debounce from 'lodash.debounce';
import { Controller } from '@hotwired/stimulus';

/**
 * Controller for autocomplete.
 */
export default class extends Controller {
  static targets = ['form', 'input', 'results'];

  /**
   * Initialize.
   */
  initialize() {
    this.debouncedSearch = _debounce(this.search, 250);
  }

  /**
   * Open autocomplete.
   */
  open() {
    this.element.classList.remove('hidden');
    this.inputTarget.focus();
  }

  /**
   * Search.
   *
   * @param {Event}  e
   */
  async search(e) {
    const response = await fetch(`${this.data.get('url')}autocomplete?query=${e.target.value}`);
    const results = await response.text();

    // Check to see if this request is up-to-date with the current input
    if (this.inputTarget.value === e.target.value) {
      this.resultsTarget.innerHTML = results;
    }
  }

  /**
   * Close.
   */
  close(e) {
    if (!this.formTarget.contains(e.target)) {
      this.resultsTarget.innerHTML = '';
      this.element.classList.add('hidden');
    }
  }
}
