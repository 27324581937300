/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * Switch locale.
   *
   * @param {Event} e
   */
  switchLocale(e) {
    e.preventDefault();
    window.location.href = e.target.value;
  }
}
