/* eslint-disable no-param-reassign, class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Cookie from '../cookie';

export default class extends Controller {
  /**
   * Cookie storage key
   * @type {String}
   */
  storageKey = 'generalNotification';

  /**
   * Connect.
   */
  connect() {
    if (!Cookie.get(this.storageKey)) {
      this.element.classList.replace('hidden', 'flex');
    }
  }

  /**
   * Hide notification.
   *
   * @param {Event} e
   */
  hide(e) {
    e.preventDefault();

    Cookie.set(this.storageKey, 'hidden');
    this.element.classList.replace('flex', 'hidden');
  }
}
