/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

const escKey = (event, callback) => {
  if (event.defaultPrevented) {
    return;
  }

  const key = event.key || event.keyCode;

  if (key === 'Escape' || key === 'Esc' || key === 27) {
    callback();
  }
};

/**
 * Modal Controller.
 */
export default class extends Controller {
  static targets = ['close'];

  escListener;

  /**
   * Show modal
   */
  show() {
    this.element.classList.remove('modal--hidden');
    document.body.classList.add('no-scroll');
    this.closeTarget.focus();

    this.escListener = document.addEventListener('keyup', (e) => escKey(e, this.close.bind(this)));
  }

  /**
   * Close modal
   */
  close() {
    this.element.classList.add('modal--hidden');
    document.body.classList.remove('no-scroll');
    document.removeEventListener('keyup', this.escListener);
  }
}
