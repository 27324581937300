/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Cookie from '../cookie';

/**
 * Controller for comparison.
 */
export default class extends Controller {
  static targets = ['popup', 'content', 'checkbox'];

  /**
   * Connect.
   */
  connect() {
    if (this.hasPopupTarget) {
      this.fetch();
    }
  }

  /**
   * Checkbox connected.
   *
   * @param {Element} checkbox
   */
  checkboxTargetConnected(checkbox) {
    const comparison = Cookie.get('product_comparison');
    if (comparison && comparison.includes(checkbox.value)) {
      checkbox.setAttribute('checked', true);
    }
  }

  /**
   * Toggle popup.
   */
  toggle() {
    this.element.querySelector('.chevron').classList.toggle('rotate-180');
    this.contentTarget.classList.toggle('hidden');
  }

  /**
   * Toggle product.
   *
   * @param {Event} e
   */
  toggleProduct(e) {
    e.stopPropagation();
    const selectedId = e.currentTarget.value;

    if (e.currentTarget.checked) {
      this.add(selectedId);
    } else {
      this.remove(selectedId);
    }
  }

  /**
   * Fetch products list.
   */
  async fetch() {
    const response = await fetch(`${document.baseURI}/actions/products/comparison/list`);
    this.popupTarget.innerHTML = await response.text();
  }

  /**
   * Add product to list.
   *
   * @param {Number} id
   */
  async add(id) {
    const data = { id };
    const response = await fetch(`${document.baseURI}/actions/products/comparison/add?${new URLSearchParams(data)}`);
    this.popupTarget.innerHTML = await response.text();
  }

  /**
   * Remove product.
   *
   * @param {Number} id
   */
  async remove(id) {
    const data = { id };
    const response = await fetch(`${document.baseURI}/actions/products/comparison/remove?${new URLSearchParams(data)}`);
    this.popupTarget.innerHTML = await response.text();
  }

  /**
   * Remove product from list.
   *
   * @param {Event} e
   */
  async removeFromList(e) {
    this.remove(e.currentTarget.dataset.remove);
  }

  /**
   * Remove product.
   *
   * @param {Event} e
   */
  async removeProduct(e) {
    await this.remove(e.currentTarget.dataset.remove);
    window.location.reload();
  }
}
