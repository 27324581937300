/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Form Controller.
 */
export default class extends Controller {
  static targets = ['form', 'fields', 'send', 'success', 'submit'];

  /**
   * Show form by subject.
   *
   * @param {Event} e
   */
  async subject(e) {
    const { id } = e.target;
    const response = await fetch(`form-${id}`);
    this.fieldsTarget.innerHTML = await response.text();
    this.fieldsTarget.classList.remove('hidden');
    this.sendTarget.classList.remove('hidden');
  }

  /**
   * Submit the form
   */
  async submit(e) {
    e.preventDefault();
    this.submitTarget.disabled = true;
    const response = await fetch(window.location.href, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: new FormData(this.element),
    });
    const result = await response.json();

    if ('success' in result || 'submission' in result) {
      this.formTarget.classList.add('hidden');
      this.successTarget.classList.remove('hidden');
    } else {
      this.submitTarget.disabled = false;
    }
  }

  /**
   * Disables form on submit.
   *
   * @param {Event} e
   */
  disableForm(e) {
    this.formTarget.disabled = true;
    e.submitter.disabled = true;
  }
}
