/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Carousel Controller.
 */
export default class extends Controller {
  static targets = ['item', 'details'];

  /**
   * @var {Number}
   */
  index = 0;

  /**
   * Select item.
   *
   * @param {Event} e
   */
  select(e) {
    this.itemTargets[this.index].classList.remove('active');
    this.detailsTargets[this.index].classList.add('hidden');

    this.index = [...e.target.parentNode.children].indexOf(e.target);

    this.itemTargets[this.index].classList.add('active');
    this.detailsTargets[this.index].classList.remove('hidden');
  }

  /**
   * Activate next item.
   */
  next() {
    this.itemTargets[this.index].classList.remove('active');
    this.detailsTargets[this.index].classList.add('hidden');

    this.index = this.index === (this.itemTargets.length - 1) ? 0 : this.index + 1;

    this.itemTargets[this.index].classList.add('active');
    this.detailsTargets[this.index].classList.remove('hidden');
  }
}
