/* global gtag */
/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'icon', 'successIcon', 'remove'];

  /**
   * Add purchasable to cart.
   *
   * @param {Event} e
   */
  async add(e) {
    e.preventDefault();

    if (this.hasButtonTarget) {
      this.toggleIcon(true);
    }

    const body = new FormData(e.target);
    const response = await fetch(window.location.href, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body,
    });

    if (response.status === 200) {
      const result = await response.json();
      const line = result.cart.lineItems.find((li) => li.purchasableId === +body.get('purchasableId'));

      const event = new CustomEvent('cart.updated', {
        detail: result.cart,
      });
      window.dispatchEvent(event);
      this.element.dispatchEvent(event);
      if (typeof gtag !== 'undefined') {
        gtag('event', 'add_to_cart', {
          currency: 'EUR',
          value: line.salePrice,
          items: [
            {
              item_id: line.sku,
              item_name: line.snapshot.product.title,
              item_variant: line.description,
              price: line.salePrice,
              quantity: line.qty,
            },
          ],
        });
      }
    } else if (this.hasButtonTarget) {
      this.toggleIcon(false);
    }
  }

  /**
   * Toggle cart icon.
   *
   * @param {Boolean} showChecked
   */
  toggleIcon(showChecked) {
    this.buttonTarget.classList.toggle('button-green', showChecked);
    this.iconTarget.classList.toggle('hidden', showChecked);
    this.successIconTarget.classList.toggle('hidden', !showChecked);
  }

  /**
   * Disable remove button when quantity is changed
   */
  changeQty() {
    this.removeTargets.forEach((target, index) => {
      this.removeTargets[index].disabled = true;
    });

    this.element.submit();
  }
}
