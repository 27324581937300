// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-16x16.png';
import '../assets/img/favicon/favicon-32x32.png';

// Locale icons
import '../assets/img/icon-locale-de.svg';
import '../assets/img/icon-locale-en.svg';
import '../assets/img/icon-locale-fr.svg';
import '../assets/img/icon-locale-nl.svg';

// Payment logos
import '../assets/img/payment/feedback-company.png';
import '../assets/img/payment/ideal.svg';
import '../assets/img/payment/mastercard.svg';
import '../assets/img/payment/paypal.svg';
import '../assets/img/payment/thuiswinkel.png';
import '../assets/img/payment/visa.svg';
import '../assets/img/payment/bancontact.svg';
import '../assets/img/payment/giropay.svg';
import '../assets/img/payment/applepay.svg';
import '../assets/img/payment/sofort.svg';
import '../assets/img/payment/klarna.svg';
import '../assets/img/payment/trustpilot.svg';

// Icons
import '../assets/img/bg-diagonal.svg';
import '../assets/img/logo.svg';
import '../assets/img/logo-white.svg';
import '../assets/img/overlay-diagonal.svg';
import '../assets/img/overlay-diagonal-hero.svg';
import '../assets/img/overlay-diagonal-inverted.svg';
import '../assets/img/icon-arrow.svg';
import '../assets/img/icon-bars.svg';
import '../assets/img/icon-ar-camera.svg';
import '../assets/img/icon-ar-scan.svg';
import '../assets/img/icon-ar-place.svg';
import '../assets/img/icon-ar.svg';
import '../assets/img/icon-ar-2.svg';
import '../assets/img/icon-3d.svg';
import '../assets/img/icon-video.svg';
import '../assets/img/icon-cart-check.svg';
import '../assets/img/icon-chevron.svg';
import '../assets/img/icon-check.svg';
import '../assets/img/icon-check-green.svg';
import '../assets/img/icon-cross.svg';
import '../assets/img/icon-cube.svg';
import '../assets/img/icon-download.svg';
import '../assets/img/icon-facebook.svg';
import '../assets/img/icon-help.svg';
import '../assets/img/icon-instagram.svg';
import '../assets/img/icon-language.svg';
import '../assets/img/icon-chevron-up-down.svg';
import '../assets/img/icon-magnifying-glass.svg';
import '../assets/img/icon-minus.svg';
import '../assets/img/icon-phone.svg';
import '../assets/img/icon-play.svg';
import '../assets/img/icon-play-simple.svg';
import '../assets/img/icon-plus.svg';
import '../assets/img/icon-related-bag.svg';
import '../assets/img/icon-search.svg';
import '../assets/img/icon-shopping-bag.svg';
import '../assets/img/icon-trash.svg';
import '../assets/img/icon-truck.svg';
import '../assets/img/icon-user.svg';
import '../assets/img/icon-youtube.svg';
import '../assets/img/icon-assets.svg';
import '../assets/img/icon-info.svg';
import '../assets/img/icon-media.svg';
import '../assets/img/icon-filters.svg';
import '../assets/img/icon-star-outline.svg';
import '../assets/img/icon-star.svg';
import '../assets/img/spinner.svg';

// Email and invoice assets
import '../assets/img/logo.png';
import '../assets/img/email-facebook.png';
import '../assets/img/email-instagram.png';
import '../assets/img/email-youtube.png';
import '../assets/img/invoice-email.png';
import '../assets/img/invoice-phone.png';
import '../assets/img/invoice-web.png';
import '../assets/img/invoice-footer.png';
import '../assets/fonts/panton-regular.ttf';
import '../assets/fonts/panton-bold.ttf';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Image fix for Safari
document.addEventListener('turbo:render', () => {
  if (navigator.userAgent.match(/(CriOS|Version)\/[\d.]+.*Safari/)) {
    document.querySelectorAll('picture').forEach((img) => {
      img.outerHTML = img.outerHTML; // eslint-disable-line no-param-reassign, no-self-assign
    });
  }
});

// Video fix for Safari
document.addEventListener('turbo:render', () => {
  if (navigator.userAgent.match(/(CriOS|Version)\/[\d.]+.*Safari/)) {
    document.querySelectorAll('video').forEach((img) => {
      img.outerHTML = img.outerHTML; // eslint-disable-line no-param-reassign, no-self-assign
    });
  }
});

// Start Turbo
Turbo.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}
