/* eslint-disable no-param-reassign, class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Cookie from '../cookie';

export default class extends Controller {
  /**
   * Cookie storage key
   * @type {String}
   */
  storageKey = 'cookiePreferences';

  /**
   * Connect.
   */
  connect() {
    const consent = Cookie.get(this.storageKey);
    if (!consent) {
      this.element.classList.remove('hidden');
    } else if (consent === 'accepted' && window.gtag) {
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      });
    }
  }

  /**
   * Show the banner.
   */
  showBanner() {
    window.parent.document.querySelector('.cookies').classList.remove('hidden');
    Array.from(window.parent.document.querySelectorAll('[data-controller="gallery embeds"]')).forEach((element) => {
      element.lightGallery.destroy();
    });
  }

  /**
   * Deny cookies.
   *
   * @param {Event} e
   */
  deny(e) {
    e.preventDefault();

    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
      });
    }

    Cookie.set(this.storageKey, 'denied');
    this.element.classList.add('hidden');
  }

  /**
   * Accept cookies.
   *
   * @param {Event} e
   */
  accept(e) {
    e.preventDefault();

    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      });
    }

    Cookie.set(this.storageKey, 'accepted');
    this.element.classList.add('hidden');
    window.location.reload();
  }
}
