/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

/**
 * Controller for product.
 */
export default class extends Controller {
  /**
   * Load product.
   *
   * @param {Event} e
   */
  async load(e) {
    e.preventDefault();
    const body = new FormData(e.currentTarget);

    const response = await fetch(window.location.pathname, {
      method: 'POST',
      credentials: 'include',
      body,
    });

    this.element.innerHTML = await response.text();
  }

  /**
   * Reload product page.
   *
   * @param {Event} e
   */
  reload(e) {
    e.preventDefault();
    const body = new FormData(this.element);

    // Parse the params
    const data = Array.from(body.entries());

    // Use Turbo to reload product
    Turbo.visit(`${window.location.pathname}?${new URLSearchParams(data)}`);
  }
}
