/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * Check for weekends.
   *
   * @param {Event} e
   */
  async checkWeekend(e) {
    const input = this.element;
    const day = new Date(input.value).getUTCDay();

    if ([6, 0].includes(day)) {
      input.setCustomValidity(e.params.message);
    } else {
      input.setCustomValidity('');
    }

    input.reportValidity();
  }
}
