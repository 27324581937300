/**
 * Salta
 *
 * @author Robuust
 * @author Sander Tijink <sander@robuust.digital>
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

Swiper.use([Autoplay]);

/**
 * USP's Swiper controller.
 */
export default class extends Controller {
  /**
   * Swiper instance.
   */
  swiper;

  /**
   * Initialize Swiper.
   */
  initialize() {
    this.swiper = new Swiper(this.element, {
      autoplay: {
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      slidesPerView: 'auto',
    });
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
  }

  /**
   * Destroy
   */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;
  }
}
