/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for filter.
 */
export default class extends Controller {
  static values = { maxFilterItems: Number };

  static targets = [
    'loader',
    'results',
    'fieldset',
    'form',
    'toggle',
    'list',
    'filters',
    'button',
  ];

  /**
   * Connect.
   */
  connect() {
    // Check if button should be shown according to the amount of items in the list
    this.listTargets.forEach((list, index) => {
      if (list.querySelectorAll('label').length <= this.maxFilterItemsValue) {
        this.buttonTargets[index].classList.add('hidden');
      }
    });
  }

  /**
   * Enable filter.
   *
   * @param {Event} e
   */
  enable(e) {
    e.currentTarget.classList.add('hidden');
    this.fieldsetTarget.classList.remove('opacity-25', 'pointer-events-none');
    this.formTarget.classList.remove('hidden');
  }

  /**
   * Toggle mobile filter.
   */
  toggleMobile() {
    this.filtersTarget.classList.toggle('hidden');
  }

  /**
   * Prevent form submission.
   *
   * @param {Event} e
   */
  prevent(e) {
    e.preventDefault();
  }

  /**
   * Submit filter
   */
  submit() {
    if (!this.hasFiltersTarget || this.filtersTarget.classList.contains('hidden')) {
      const formEntries = new FormData(this.formTarget).entries();
      const params = Array.from(formEntries);
      this.apply(params);
    }
  }

  /**
   * Submit mobile filter.
   */
  submitMobile() {
    const formEntries = new FormData(this.formTarget).entries();
    const params = Array.from(formEntries);
    this.apply(params);
  }

  /**
   * Changing shape resets size.
   */
  changeShape() {
    Array.from(this.formTarget['size[]']).forEach((size) => {
      size.setAttribute('value', '');
    });
    this.submit();
  }

  /**
   * Search for query + reset other filters
   *
   * @param {Event} e
   */
  search(e) {
    const params = { query: e.currentTarget.value };
    this.apply(params);
  }

  /**
   * Toggle filter state
   *
   * @param {Event} e
   */
  toggle(e) {
    const index = parseInt(e.currentTarget.dataset.toggleIndex, 10);
    this.toggleTargets.forEach((target, targetIndex) => {
      if (targetIndex !== index) {
        target.classList.add('hidden');
      } else {
        target.classList.toggle('hidden');
      }
    });
  }

  /**
   * Apply filter.
   *
   * @param {Object} params
   */
  async apply(params) {
    this.loaderTarget.classList.remove('hidden');
    this.fieldsetTarget.disabled = true;
    const querystring = new URLSearchParams(params);
    const url = `${this.formTarget.action}?${querystring}`;

    window.history.pushState({}, document.title, url);

    querystring.append('isPartial', 1);
    const response = await fetch(`${this.formTarget.action}?${querystring}`);

    this.resultsTarget.innerHTML = await response.text();
    this.fieldsetTarget.disabled = false;
    this.loaderTarget.classList.add('hidden');
  }

  /**
   * Toggle more or less items in filter list.
   *
   * @param {Event} e
   */
  toggleExpandFilter(e) {
    const list = e.currentTarget.parentNode;

    list.querySelectorAll('label').forEach((field, index) => {
      if (list.isExpanded && index >= this.maxFilterItemsValue) {
        field.classList.add('hidden');
      } else if (!list.isExpanded) {
        field.classList.remove('hidden');
      }
    });

    // Button state
    e.currentTarget.classList.toggle('is-expanded', !list.isExpanded);

    list.isExpanded = !list.isExpanded;
  }
}
