/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';

Swiper.use([Autoplay, EffectFade, Pagination]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['wrapper', 'pagination', 'thumb'];

  static values = {
    options: Object,
  };

  /**
   * Swiper instance.
   */
  swiper;

  /**
   * Init.
   */
  initialize() {
    let options = {
      effect: 'fade',
      crossFade: false,
      autoplay: {
        delay: 5000,
      },
      loop: true,
    };

    if (this.hasOptionsValue) {
      options = this.optionsValue;
    }

    this.swiper = new Swiper(this.wrapperTarget, {
      ...options,
      // If we need pagination
      pagination: {
        el: this.hasPaginationTarget ? this.paginationTarget : undefined,
      },
    });

    if (this.thumbTargets.length) {
      // On slide change event
      this.swiper.on('slideChange', (swiper) => {
        // eslint-disable-next-line max-len
        this.thumbTargets.forEach((thumb, index) => thumb.classList.toggle(this.activeClass, swiper.activeIndex === index));
      });
    }
  }

  /**
   * Slide to index.
   *
   * @param {Event} {params}
   */
  onSlideTo({ params }) {
    this.swiper.slideTo(params.index);
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
  }

  /**
   * Destroy.
   */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;
  }
}
