/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

/**
  * AR Controller.
  */
export default class extends Controller {
  static targets = ['ar', 'qr', 'video'];

  /**
   * @var {Number}
   */
  timer;

  /**
   * Feature detect on connect.
   */
  async connect() {
    const hasArSupport = await this.hasArSupport();

    this.arTargets.forEach((ar) => {
      ar.classList.toggle('hidden', !hasArSupport);
    });
    this.qrTargets.forEach((qr) => {
      qr.classList.toggle('hidden', !!hasArSupport);
    });

    if (this.hasVideoTarget) {
      this.startVideo();

      document.addEventListener('click', () => {
        this.videoTarget.classList.add('hidden');
        clearTimeout(this.timer);
        this.startVideo();
      });
    }
  }

  /**
   * Detect AR support.
   *
   * @return {Boolean}
   */
  hasArSupport() {
    const a = document.createElement('a');
    return a.relList.supports('ar') || (navigator.xr && navigator.xr.isSessionSupported('immersive-ar'));
  }

  /**
   * Set product line.
   *
   * @param {Event} e
   */
  setProductLine(e) {
    e.preventDefault();
    const body = new FormData();
    body.set('productLineId', e.target.value);

    if (e.target.form.language) {
      body.set('language', e.target.form.language.value);
    }

    if (e.target.form.unit) {
      body.set('unit', e.target.form.unit.value);
    }

    if (e.target.form.type) {
      body.set('type', e.target.form.type.value);
    }

    if (e.target.form.embed) {
      body.set('embed', e.target.form.embed.value);
    }

    // Parse the params
    const data = Array.from(body.entries());

    // Use Turbo to reload product
    Turbo.visit(`${window.location.pathname}?${new URLSearchParams(data)}`);
  }

  /**
   * Set size.
   *
   * @param {Event} e
   */
  setSize(e) {
    e.preventDefault();
    const body = new FormData();
    body.set('productLineId', e.target.form.productLineId.value);
    body.set('size', e.target.value);

    if (e.target.form.sl) {
      body.set('sl', e.target.form.sl.value);
    }

    if (e.target.form.language) {
      body.set('language', e.target.form.language.value);
    }

    if (e.target.form.unit) {
      body.set('unit', e.target.form.unit.value);
    }

    if (e.target.form.type) {
      body.set('type', e.target.form.type.value);
    }

    if (e.target.form.embed) {
      body.set('embed', e.target.form.embed.value);
    }

    // Parse the params
    const data = Array.from(body.entries());

    // Use Turbo to reload product
    Turbo.visit(`${window.location.pathname}?${new URLSearchParams(data)}`);
  }

  /**
   * Set color.
   *
   * @param {Event} e
   */
  setColor(e) {
    e.preventDefault();
    const body = new FormData();
    body.set('productLineId', e.target.form.productLineId.value);
    body.set('size', e.target.form.size.value);
    body.set('color', e.target.value);

    if (e.target.form.sl) {
      body.set('sl', e.target.form.sl.value);
    }

    if (e.target.form.language) {
      body.set('language', e.target.form.language.value);
    }

    if (e.target.form.unit) {
      body.set('unit', e.target.form.unit.value);
    }

    if (e.target.form.type) {
      body.set('type', e.target.form.type.value);
    }

    if (e.target.form.embed) {
      body.set('embed', e.target.form.embed.value);
    }

    // Parse the params
    const data = Array.from(body.entries());

    // Use Turbo to reload product
    Turbo.visit(`${window.location.pathname}?${new URLSearchParams(data)}`);
  }

  /**
   * Start video.
   */
  startVideo() {
    this.timer = setTimeout(() => {
      this.videoTarget.classList.remove('hidden');
      this.videoTarget.firstElementChild.load();
    }, 20000);
  }
}
