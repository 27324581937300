/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for navigation.
 */
export default class extends Controller {
  static targets = ['logo', 'cartQty'];

  /**
   * Toggle nav item.
   *
   * @param {Event} e
   */
  toggle(e) {
    e.target.closest('li').classList.toggle('list-expanded');
  }

  /**
   * Toggle portal nav item.
   *
   * @param {Event} e
   */
  togglePortal(e) {
    e.target.nextElementSibling.classList.toggle('hidden');
  }

  /**
   * Toggle navigation mobile menu
   */
  toggleMobile() {
    this.element.classList.toggle('menu-is-expanded');
  }

  /**
   * Toggle search event.
   */
  toggleSearch() {
    const event = new CustomEvent('nav.search', {
      detail: true,
    });
    window.dispatchEvent(event);
  }

  /**
   * Show cart qty.
   *
   * @param {Event} e
   */
  updateCart(e) {
    const cart = e.detail;
    this.cartQtyTargets.forEach((target) => {
      target.innerText = cart.totalQty; // eslint-disable-line no-param-reassign
    });
  }

  /**
   * Toggle portal sidebar.
   */
  toggleSidebar() {
    const sidebar = this.element.nextElementSibling.firstElementChild;
    sidebar.classList.toggle('hidden');
    sidebar.classList.toggle('block');
  }
}
