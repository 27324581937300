/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

/**
 * Controller for select.
 */
export default class extends Controller {
  /**
   * Visit selected value.
   *
   * @param {Event} e
   */
  select(e) {
    Turbo.visit(`${window.location.pathname}?${e.target.name}=${e.target.value}`);
  }
}
