/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for tabs.
 */
export default class extends Controller {
  static targets = ['tabs', 'content'];

  static values = { style: String };

  /**
   * Read hash to open specific tab.
   */
  connect() {
    const { hash } = window.location;
    if (hash !== '') {
      this.tabsTarget.querySelectorAll('[data-tab-index][href]').forEach((anchor) => {
        const target = `#${anchor.href.split('#')[1]}`;
        this.toggle(anchor, target === hash);
      });

      this.contentTargets.forEach((content) => {
        const target = `#${content.id}`;
        content.classList.toggle('hidden', target !== hash);
      });
    }
  }

  /**
   * Activate tab.
   */
  activate(e) {
    e.preventDefault();
    const { tabIndex } = e.target.dataset;

    this.tabsTarget.querySelectorAll('[data-tab-index]').forEach((anchor) => {
      const index = anchor.dataset.tabIndex;
      this.toggle(anchor, tabIndex === index);
    });

    this.contentTargets.forEach((content) => {
      const index = content.dataset.tabIndex;
      content.classList.toggle('hidden', tabIndex !== index);
    });
  }

  /**
   * Toggle styles.
   *
   * @param {Element} anchor
   * @param {Boolean} selected
   */
  toggle(anchor, selected) {
    switch (this.styleValue) {
      case 'portal':
        anchor.classList.toggle('border-green-500', selected);
        anchor.classList.toggle('text-white', selected);
        anchor.classList.toggle('border-transparent', !selected);
        anchor.classList.toggle('text-gray-400', !selected);
        break;
      default:
        anchor.classList.toggle('bg-black', selected);
        anchor.classList.toggle('text-white', selected);
        anchor.classList.toggle('focus:outline-none', selected);
        break;
    }
  }
}
