/* eslint-disable class-methods-use-this */
/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'form'];

  /**
   * Show discount code field.
   */
  showField() {
    this.triggerTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');
    this.formTarget.querySelector('input').focus();
  }

  /**
   * Act on enter.
   *
   * @param {Event} e
   */
  enter(e) {
    if (e.which === 13) {
      e.preventDefault();
    }
  }
}
