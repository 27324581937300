/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for wizard options.
 */
export default class extends Controller {
  static targets = ['container', 'option', 'name'];

  /**
   * Change selected option.
   */
  selectOption() {
    ['text-green-500', 'text-black'].map((c) => this.nameTarget.classList.toggle(c));
  }

  /**
   * Show options.
   *
   * @param {HTMLFormElement} form
   */
  show(form) {
    this.optionTargets.forEach((option) => {
      if (option.dataset.filterConditionals) {
        const conditionals = JSON.parse(option.dataset.filterConditionals);
        const show = conditionals.every((conditional) => conditional.value === form[`${conditional.filter}[]`].value);
        option.parentNode.classList.toggle('hidden', !show);
      }
    });
    this.containerTarget.classList.remove('hidden');
    ['text-gray-500', 'text-black'].map((c) => this.nameTarget.classList.toggle(c));
    this.element.scrollIntoView({ behavior: 'smooth' });
  }
}
