/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Returns item controller.
 */
export default class extends Controller {
  static targets = [
    'shippingText',
  ];

  /**
   * Toggle enabled.
   *
   * @param {Event} e
   */
  toggleEnabled(e) {
    const checkbox = e.target;
    if (checkbox.dataset.isTrampoline === 'true') {
      this.shippingTextTarget.classList.toggle('hidden', !checkbox.checked);
    }
  }
}
