/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';

Swiper.use();

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper'];

  /**
   * @var {Swiper}
   */
  swiper;

  /**
   * @var {IntersectionObserver}
   */
  observer;

  /**
   * Init.
   */
  initialize() {
    this.swiper = new Swiper(this.wrapperTarget, {
      slidesPerView: 'auto',
      autoplay: {
        delay: 5000,
      },
    });
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.swiper.autoplay.start();
      }
    });
  }

  /**
   * Mount.
   */
  wrapperTargetConnected(element) {
    this.swiper?.enable();
    this.swiper?.autoplay.stop();
    this.observer?.observe(element);
  }

  /**
   * Destroy.
   */
  wrapperTargetDisconnected(element) {
    this.swiper?.destroy(true);
    this.swiper = undefined;
    this.observer?.unobserve(element);
  }
}
