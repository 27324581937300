/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * App Controller.
 */
export default class extends Controller {
  /**
   * Show modal
   *
   * @param {Event} event
   */
  showModal(event) {
    const id = event.currentTarget.dataset.modalId;
    const modal = document.getElementById(`modal-${id}`);
    const controller = this.application.getControllerForElementAndIdentifier(modal, 'modal');

    if (controller) {
      controller.show();
    } else {
      throw Error('Modal controller not found');
    }
  }
}
